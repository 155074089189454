import { bind, wire } from 'hyperhtml/esm'
import { formFields } from'./formFields'




export const formElement = (ctx, renderedFields, formData, formFinished, submitted) => {
    return wire(ctx, ':form')`
        <form>
            ${[...renderedFields].map(
                field => [field, formFields[field]]
            ).map(
                ([name, {question, UI, required}]) => ({
                    ...UI,
                    question,
                    name,
                    value: formData.get(name),
                    className: createClassName(renderedFields, name, required),
                })
            ).map(k => formUI[k.type].call(ctx, k))}
            <div class="submit-area">
                ${formInfo(ctx, submitted)}
                ${wire(ctx, ':submit-button')`<input class="${formFinished ? undefined : 'form_unfinished'}" type="submit" value="submit"/>`}
            </div>
        </form>
    `
}

const createClassName = (renderedFields, name, required) => {
    const shown = renderedFields.has(name)
    let className = []
    if (shown) {
        className.push("shown")
    } else {
        className.push("hidden")
    }
    if (required) {
        className.push("required")
    }
    return className.join(" ")  
}

const formInfo = (ctx, submitted) => {
    return wire(ctx, ':form-info')`
        <span class="${`formInfo${submitted ? ' submit_done' : ''}`}">
            ${submitted ? "Success!" : "* Required"}
        </span>
    `
}

const formUI = {
    select: function({choices, name, value, question, className}) {
        return wire(this, `html:${name}`)`
            <label class="${className}">
            ${question}
                <select name="${name}">
                    <option disabled selected>Choose</option>
                    ${choices.map(
                        choice => wire()`
                            <option value="${choice}" selected=${choice === value}>
                            ${choice}
                            </option>`
                    )}
                </select>
            </label>
        `   
    },       
    text: function({name, value, question, className, autocomplete}) {
        return wire(this, `html:${name}`)`
            <label class="${className}">
                ${question}
                <input name="${name}" autocomplete="${autocomplete}" type="text" value="${value}"/>
            </label>
        `
    },
    date: function({name, value, question, className}) {
        return wire(this, `html:${name}`)`
            <label class="${className}">
                ${question}
                <input name="${name}" type="date" value="${value}"/>
            </label>
        `
    },
    email: function({name, value, question, className, autocomplete}) {
        return wire(this, `html:${name}`)`
            <label class="${className}">
                ${question}
                <input name="${name}" type="email" value="${value}" autocomplete="${autocomplete}" />
            </label>
        `
    },
    file: function({name, value, question, className}) {
        return wire(this, `html:${name}`)`
            <label class="${className}">
                ${question}
                <input name="${name}" type="file"/>
            </label>
        `
    },
    phone: function({name, value, question, className, autocomplete}) {
        return wire(this, `html:${name}`)`
            <label class="${className}">
                ${question}
                <input type="tel" value="${value}" name="${name}" autocomplete="${autocomplete}" />
            </label>
        `
    },
    textarea: function({name, value, question, className}) {
        return wire(this, `html:${name}`)`
            <label class="${className}">
                ${question}
                <textarea name="${name}" value="${value}"></textarea>
            </label>
        `
    }
}