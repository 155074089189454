

export const traverseFields = (root, fields, formData=new FormData(), rendered=new Set()) => {
    try {
        rendered.add(root)
        const next = fields[root].next(formData.get(root))
        return next.reduce(
            (a, name) => {
                traverseFields(name, fields, formData, a)
                return a
            }, rendered
        )
    } catch (e) {
        console.log(e)
    }
}


export const formFields = {
    firstName: {
        next: () => ['lastName', 'email', 'phone', 'projectType'],
        question: "What is your first name?",
        UI: {
            type: "text",
            autocomplete: "given-name"
        },
        required: true
    },
    lastName: {
        next: () => [],
        question: "What is your last name?",
        UI: {
            type: "text",
            autocomplete: "family-name"
        },
        required: true
    },
    email: {
        next: () => [],
        question: "What is your email address?",
        UI: {
            type: "email",
            autocomplete: "email"
        },
        required: true
    },
    phone: {
        next: () => [],
        question: "What is your phone number?",
        UI: {
            type: "phone",
            autocomplete: "tel"
        },
        required: true
    },
    projectType: {
        next: v => {
            switch (v) {
                case "Condo":
                    return ['condoType']
                    break
                case "LLC":
                    return ['LLCType']
                    break
                case "Other":
                    return ['describeOtherTypeProject']
                    break
                default:
                    return []
                    break
            }
        },
        question: "What kind of project do you have?",
        required: true,
        UI: {
            type: "select",
            choices: [
                "Condo",
                "LLC",
                "Other"
            ]
        },
    },
    condoType: {
        next: v => {
            switch (v) {
                case "Existing Residential Construction":
                    return [
                        'isProjectAttached', 
                        'doesLiveOnProperty', 
                        'numberOfUnitsSelling',
                        'areUnitsAlreadyListed',
                        'address',
                        'commentsOrQuestions',
                        'referredBy'
                    ]
                    break
                case "New Residential Construction":
                    return [
                        'isProjectAttached', 
                        'doesLiveOnProperty', 
                        'numberOfUnitsSelling',
                        'areUnitsAlreadyListed',
                        'address',
                        'commentsOrQuestions',
                        'referredBy'
                    ]
                    break
                case "Mixed Use Condo":
                    return [ 
                        'numberOfUnitsSelling',
                        'areUnitsAlreadyListed',
                        'address',
                        'commentsOrQuestions',
                        'referredBy'
                    ]
                    break
                case "Commercial Condo":
                    return [ 
                        'numberOfUnitsSelling',
                        'areUnitsAlreadyListed',
                        'address',
                        'commentsOrQuestions',
                        'referredBy'
                    ]
                    break
                case "Multifamily Condo":
                    return [ 
                        'numberOfUnitsSelling',
                        'areUnitsAlreadyListed',
                        'address',
                        'commentsOrQuestions',
                        'referredBy'
                    ]
                    break
                default:
                    return []
                    break
            }
        },
        question: "What kind of condo project is it?",
        UI: {
            type: "select",
            choices: [
                "Existing Residential Construction",
                "New Residential Construction",
                "Mixed Use Condo",
                "Commercial Condo",
                "Multifamily Condo"
            ]
        }
    },
    commentsOrQuestions: {
        question: "Do you have any comments or questions?",
        UI: {
            type: "textarea"
        },
        next: () => []
    },
    isProjectAttached: {
        next: () => [],
        question: "Are the units attached?",
        UI: {
            type: "select",
            choices: ["Yes", "No"]
        }
    },
    numberOfUnitsSelling: {
        next: () => [],
        question: "How many units?",
        UI: {
            type: "text"
        }
    },
    LLCType: {
        next: v => {
            switch (v) {
                case "Other":
                    return ['LLCOther']
                    break
                case "Standard LLC":
                    return ['LLCPropertyNumber', 'LLCMemberNumber', 'commentsOrQuestions', 'referredBy']
                    break
                case "Series LLC":
                    return ['LLCPropertyNumber', 'LLCMemberNumber', 'commentsOrQuestions', 'referredBy']
                    break
                default:
                    return []
                    break
            }
        },
        question: "What kind of LLC are you forming?",
        UI: {
            type: "select",
            choices: [
                "Standard LLC",
                "Series LLC",
                "Other"
            ]
        }
    },
    LLCOther: {
        next: () => ['commentsOrQuestions', 'referredBy'],
        question: "Please describe the purpose of the LLC",
        UI: {
            type: "textarea"
        }
    },
    LLCPropertyNumber: {
        next: () => [],
        question: "How many properties will be held in the LLC?",
        UI: {
            type: "text"
        }
    },
    LLCMemberNumber: {
        next: () => [],
        question: "How many members will be in the LLC?",
        UI: {
            type: "text"
        }
    },
    referredBy: {
        next: () => [],
        question: "How did you hear about us?",
        UI: {
            type: "textarea"
        }
    },
    describeOtherTypeProject: {
        next: () => ['commentsOrQuestions', 'referredBy'],
        question: "Please describe your project",
        UI: {
            type: "textarea"
        }
    },
    developerOrOwner: {
        next: () => [],
        question: "Are you a Developer or an Owner?",
        UI: {
            type: "select",
            choices: [
                "Developer",
                "Owner"
            ]
        }
    },
    address: {
        next: () => [],
        question: "What is the address of your property?",
        UI: {
            type: "text"
        }
    },
    doesLiveOnProperty: {
        next: () => [],
        question: "Do you also live on the property?",
        UI: {
            type: "select",
            choices: ["Yes", "No"]
        }
    },
    areUnitsAlreadyListed: {
        next: v => {
            switch (v) {
                case "Yes":
                    return ['upcomingClosingDates']
                    break
                case "No":
                    return ['listingWhen']
                    break
                default:
                    return []
                    break

            }
        },
        question: "Are the units already on the market?",
        UI: {
            type: "select",
            choices: ["Yes", "No"]
        }
    },
    upcomingClosingDates: {
        next: () => [],
        question: "When is the nearest closing date?",
        UI: {
            type: "date"
        }
    },
    listingWhen: {
        next: () => [],
        question: "When do you plan on listing the property?",
        UI: {
            type: "date"
        }
    }
}





