import React from "react";
// import logo from "./logo.svg";
import "./App.css";
import "./globalStyles.css";
// import NavbarWithSubnavigation from "./NavbarWithSubnavigation.js";

import Header from "./components/Header";
import About from "./components/About";
import AboutTwo from "./components/AboutTwo";
import Footer from "./components/Footer";
import ProposalForm from "./components/ProposalForm";
// import PreviousProjects from "./components/PreviousProjects";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <main>
        <Header />
        {/* <PreviousProjects/>  */}
        <ProposalForm />

        {/* <AboutTwo /> */}
        {/* <About /> */}
        {/* <Footer /> */}
      </main>
    </Router>
  );
}

export default App;
