import React from "react";
import "./style.css";
import "./proposalForm";
import { useLocation, useHistory } from "react-router-dom";

export default () => {
  let location = useLocation();
  let history = useHistory();
  console.log(location);
  const el = React.useRef(null);
  React.useEffect(() => {
    //   customElements.whenDefined("proposal-form").then(() => {
    //     // console.log("log");
    //     if (location.pathname === "/project-proposal") {
    //       console.log("log");
    //       el.current.isOpen = true;
    //     } else {
    //       el.current.isOpen = false;
    //     }
    //     el.current.addEventListener(el.current.TOTALLYDONE, function () {
    //       history.push("/");
    //     });
    //   }, [location]);
    // });

    console.log("log");
    if (location.pathname === "/project-proposal") {
      el.current.isOpen = true;
    } else {
      el.current.isOpen = false;
    }
    el.current.addEventListener(el.current.TOTALLYDONE, function () {
      history.push("/");
    });
  }, [location]);
  return <proposal-form ref={el}></proposal-form>;
};
/*
export default class extends React.Component {
	render() {
		return (
			<proposal-form ref={this.myRef}>
			</proposal-form>
		)

	}
	componentDidMount() {
		this.myRef.current.isOpen=true
	}
	componentWillUnmount() {
		this.myRef.current.isOpen=false
	}
	constructor(props) {
	    super(props);
	    this.myRef = React.createRef();
	}

}
	*/
