import firebase from 'firebase/app'  // or try: import firebase from 'firebase/compat/app'
import 'firebase/functions'

var config = {
    apiKey: "AIzaSyCjoK5dtV_uvojtMTj8Q2FjmvjlEQFtqLo",
    authDomain: "local-dimension-201302.firebaseapp.com",
    databaseURL: "https://local-dimension-201302.firebaseio.com",
    projectId: "local-dimension-201302",
    storageBucket: "local-dimension-201302.appspot.com",
    messagingSenderId: "497574886673"
};

firebase.initializeApp(config);

export default firebase.functions().httpsCallable('handleForm')

// export default () => new Promise((res, rej) => setTimeout(() => {
//     console.log('submit sent')
//     res()
// }, 2000))

