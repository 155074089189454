import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

export default () => (
  // <header>
  //   <div className="opacity">
  //     <h1>Austin Condo Law</h1>
  //     {/* <h1>Julie Alexander Law</h1> */}
  //     {/* <h1>
  //       Condo <strong>Conversions</strong>
  //       <br />
  //       &amp; Real Estate Law
  //     </h1> */}

  //     <h2>
  //       Condo <strong>Conversions</strong>
  //       <br />
  //       &amp; Real Estate Law
  //     </h2>
  //     {/* <h2>
  //       1,000+ condo&shy;minium regimes in Austin and across Texas for 2 to 100+
  //       unit projects.
  //       Julie Alexander focuses on creating condo&shy;minium regimes for real
  //       estate develop&shy;ment projects in Austin and across Texas. She has
  //       created over 1,000 condo&shy;minium regimes for 2 to 100+ unit projects.
  //     </h2> */}
  //     <Link to="/project-proposal">Request a Project Proposal </Link>
  //   </div>
  // </header>
  <hero>
    {/* <h1>Austin Condo Law</h1> */}
    {/* <h1>Julie Alexander Law</h1> */}
    {/* <h1>
        Condo <strong>Conversions</strong>
        <br />
        &amp; Real Estate Law
      </h1> */}
    <h1>
      Condo <strong>Conversions</strong>
      <br />
      across Texas
    </h1>
    {/* <h2>
      Condo <strong>Conversions</strong>
      <br />
      &amp; Real Estate Law
    </h2> */}

    {/* <p>
      <strong>Julie Alexander</strong> creates{" "}
      <strong>condo&shy;minium regimes</strong> and{" "}
      <strong>owners' associations</strong> for{" "}
      <strong>real estate develop&shy;ment</strong> projects in Austin and
      across <strong>Texas.</strong>
    </p>

    <p>
      She has created <strong>over 1,750 condo regimes</strong> for projects
      with <strong>2 units to 100+ units</strong> in{" "}
      <strong>
        Austin, San Antonio, Port Aransas, Dallas, Fort Worth, Waxahachie,
        Houston
      </strong>{" "}
      and surrounding areas.
    </p>

    <p>
      Her condominium projects include{" "}
      <strong>
        residential, multifamily, mixed-use, commercial, retail, medical, office
      </strong>{" "}
      and <strong>industrial</strong> condominium developments.
    </p> */}

    {/* <h2>
        1,000+ condo&shy;minium regimes in Austin and across Texas for 2 to 100+
        unit projects.
        Julie Alexander focuses on creating condo&shy;minium regimes for real
        estate develop&shy;ment projects in Austin and across Texas. She has
        created over 1,000 condo&shy;minium regimes for 2 to 100+ unit projects.
      </h2> */}

    {/* <Link to="/project-proposal">Request a Project Proposal </Link> */}
    <a href="https://www.texascondolaw.com/" target="_blank">
      Request a Project Proposal
    </a>
  </hero>
);
