//import '@webcomponents/webcomponentsjs/webcomponents-lite.js'
import { bind, wire } from "hyperhtml/esm";
import { fromEvent, mergeArray } from "most";
import axios from "axios";
import { traverseFields, formFields } from "./formFields";
import { formElement } from "./formui";
import handleForm from "./handleForm";

customElements.define(
  "proposal-form",
  class extends HTMLElement {
    constructor() {
      super();
      this.FINISHED_QUESTION = "FINISHED_QUESTION";
      this.TRANSITIONEND = "TRANSITIONEND";
      this.INPUT = "INPUT";
      this.SUBMIT = "SUBMIT";
      this.SUBMIT_RESPONSE = "SUBMIT_RESPONSE";
      this.SUBMITTED = "SUBMITTED";
      this.OPENCLOSE = "OPENCLOSE";
      this.TOTALLYDONE = "TOTALLYDONE";
      //issue is with initial state
      this.initialState = {
        formFinished: false,
        awaitingSubmit: false,
        formData: new FormData(),
        renderedFields: traverseFields("firstName", formFields),
        submitResult: undefined,
        open: false,
        transitioning: false,
        totallyFinished: false,
        submitted: false,
      };

      this.getActionStream()
        .scan(this.reducer.bind(this), this.initialState)
        .skip(1)
        .forEach(this.makeSideEffects.bind(this));
    }

    connectedCallback() {
      for (const sheet of document.styleSheets) {
        const k = Array.from(sheet.cssRules).find(
          (a) => a.selectorText === "proposal-form.animating.form_shown"
        );
        if (k !== undefined) {
          this.trickyStyleRule = k.style;
          break;
        }
      }
    }

    set isOpen(isOpen) {
      this.dispatchEvent(
        new CustomEvent(this.OPENCLOSE, {
          detail: isOpen,
        })
      );
      this.open = isOpen;
    }

    reducer(prevState, { type, payload }) {
      switch (type) {
        case this.INPUT:
          const newFormData = new FormData();
          for (const [key, value] of prevState.formData) {
            newFormData.set(key, value);
          }
          for (const [key, value] of payload.formData) {
            newFormData.set(key, value);
          }
          return {
            ...prevState,
            formData: newFormData,
          };
          break;
        case this.FINISHED_QUESTION:
          const newRenderedFields = traverseFields(
            "firstName",
            formFields,
            prevState.formData
          );
          return {
            ...prevState,
            renderedFields: newRenderedFields,
            formFinished: [...newRenderedFields]
              .filter((name) => formFields[name].required)
              .every((name) =>
                ((value) =>
                  value !== undefined && value !== null && value !== "")(
                  prevState.formData.get(name)
                )
              ),
          };
          break;
        case this.SUBMIT:
          if (prevState.formFinished) {
            return {
              ...prevState,
              awaitingSubmit: true,
            };
          } else {
            return prevState;
          }
          break;
        case this.SUBMIT_RESPONSE:
          return {
            ...prevState,
            submitResult: payload.submitResult,
            totallyFinished: true,
          };
          break;
        case this.SUBMITTED:
          return {
            ...prevState,
            awaitingSubmit: false,
            submitted: true,
          };
          break;
        case this.TRANSITIONEND:
          return {
            ...prevState,
            transitioning: false,
          };
          break;
        case this.OPENCLOSE:
          if (prevState.open !== payload.isOpen) {
            return {
              ...prevState,
              open: payload.isOpen,
              transitioning: true,
            };
          } else {
            return prevState;
          }
          break;
        default:
          return prevState;
      }
    }

    getActionStream() {
      const input$ = fromEvent("input", this).map((e) => ({
        type: this.INPUT,
        payload: {
          formData: new FormData(this.querySelector("form")),
        },
      }));

      const openclose$ = fromEvent(this.OPENCLOSE, this).map((e) => ({
        type: this.OPENCLOSE,
        payload: {
          isOpen: e.detail,
        },
      }));

      const submit$ = fromEvent("submit", this).map(
        (e) =>
          e.preventDefault() || {
            type: this.SUBMIT,
          }
      );

      const finishedQuestion$ = fromEvent("input", this)
        .debounce(700)
        .map((e) => ({
          type: this.FINISHED_QUESTION,
        }));

      const submitted$ = fromEvent(this.SUBMITTED, this).map((e) => ({
        type: this.SUBMITTED,
      }));

      const submitResponse$ = fromEvent(this.SUBMIT_RESPONSE, this).map(
        (e) => ({
          type: this.SUBMIT_RESPONSE,
          payload: {
            submitResult: e.detail,
          },
        })
      );

      const transitionEnd$ = fromEvent("transitionend", this)
        .filter((e) => e.target === this.querySelector("form"))
        .map((e) => ({
          type: this.TRANSITIONEND,
        }));

      return mergeArray([
        input$,
        submit$,
        finishedQuestion$,
        transitionEnd$,
        submitResponse$,
        openclose$,
        submitted$,
      ]);
    }

    submitForm(state) {
      const { awaitingSubmit, formData, renderedFields } = state;
      if (awaitingSubmit === true) {
        const toSubmit = [...formData.entries()].reduce((a, [k, v]) => {
          if (renderedFields.has(k)) {
            a[k] = v;
          }
          return a;
        }, {});
        toSubmit["timeStamp"] = new Date().toDateString();
        this.dispatchEvent(new CustomEvent(this.SUBMITTED));
        window.gtag("event", "form_submit", {
          event_category: "form",
        });
        return handleForm(toSubmit)
          .then((data) =>
            this.dispatchEvent(
              new CustomEvent(this.SUBMIT_RESPONSE, {
                detail: data,
              })
            )
          )
          .catch((error) =>
            this.dispatchEvent(
              new CustomEvent(this.SUBMIT_RESPONSE, {
                detail: error,
              })
            )
          );
      } else {
        return;
      }
    }

    render({ renderedFields, formData, formFinished, submitted }) {
      return bind(this)`
            <h2>Request a Project Proposal</h2>
            ${formElement(
              this,
              renderedFields,
              formData,
              formFinished,
              submitted
            )}
        `;
    }

    transition(state) {
      if (state.transitioning) {
        if (this.classList.contains("form_hidden")) {
          this.classList.remove("form_hidden");
          this.classList.add("form_shown");
          this.classList.add("animating");
          this.scrollIntoView(true);
        } else {
          const styleRule = this.trickyStyleRule;
          const prevValue = styleRule.getPropertyValue("--form-height");
          const formHeight = getComputedStyle(
            this.querySelector("form")
          ).getPropertyValue("height");
          styleRule.setProperty("--form-height", formHeight);
          this.scrollIntoView(true);
          requestAnimationFrame(() => {
            this.classList.add("animating");
            requestAnimationFrame(() => {
              this.classList.remove("form_shown");
              this.classList.add("form_hidden");
              styleRule.setProperty("--form-height", prevValue);
            });
          });
        }
      } else {
        this.classList.remove("animating");
        if (state.open) {
          this.classList.add("form_shown");
          this.classList.remove("form_hidden");
        } else {
          this.classList.add("form_hidden");
          this.classList.remove("form_shown");
        }
      }
    }

    closeIfDone(state) {
      if (state.totallyFinished && state.open && !state.transitioning) {
        this.isOpen = false;
        this.dispatchEvent(new CustomEvent(this.TOTALLYDONE));
      }
    }

    makeSideEffects(state) {
      //try reversing render and transition below.
      this.transition(state);
      this.render(state);
      this.submitForm(state);
      this.closeIfDone(state);
    }
  }
);
